"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  Modal: true,
  ModalButton: true,
  FocusOnce: true,
  SIZE: true,
  ROLE: true,
  CLOSE_SOURCE: true,
  StyledRoot: true,
  StyledDialog: true,
  StyledDialogContainer: true,
  StyledClose: true,
  ModalHeader: true,
  ModalBody: true,
  ModalFooter: true
};
Object.defineProperty(exports, "CLOSE_SOURCE", {
  enumerable: true,
  get: function () {
    return _constants.CLOSE_SOURCE;
  }
});
Object.defineProperty(exports, "FocusOnce", {
  enumerable: true,
  get: function () {
    return _focusOnce.default;
  }
});
Object.defineProperty(exports, "Modal", {
  enumerable: true,
  get: function () {
    return _modal.default;
  }
});
Object.defineProperty(exports, "ModalBody", {
  enumerable: true,
  get: function () {
    return _styledComponents.ModalBody;
  }
});
Object.defineProperty(exports, "ModalButton", {
  enumerable: true,
  get: function () {
    return _modalButton.default;
  }
});
Object.defineProperty(exports, "ModalFooter", {
  enumerable: true,
  get: function () {
    return _styledComponents.ModalFooter;
  }
});
Object.defineProperty(exports, "ModalHeader", {
  enumerable: true,
  get: function () {
    return _styledComponents.ModalHeader;
  }
});
Object.defineProperty(exports, "ROLE", {
  enumerable: true,
  get: function () {
    return _constants.ROLE;
  }
});
Object.defineProperty(exports, "SIZE", {
  enumerable: true,
  get: function () {
    return _constants.SIZE;
  }
});
Object.defineProperty(exports, "StyledClose", {
  enumerable: true,
  get: function () {
    return _styledComponents.Close;
  }
});
Object.defineProperty(exports, "StyledDialog", {
  enumerable: true,
  get: function () {
    return _styledComponents.Dialog;
  }
});
Object.defineProperty(exports, "StyledDialogContainer", {
  enumerable: true,
  get: function () {
    return _styledComponents.DialogContainer;
  }
});
Object.defineProperty(exports, "StyledRoot", {
  enumerable: true,
  get: function () {
    return _styledComponents.Root;
  }
});
var _modal = _interopRequireDefault(require("./modal"));
var _modalButton = _interopRequireDefault(require("./modal-button"));
var _focusOnce = _interopRequireDefault(require("./focus-once"));
var _constants = require("./constants");
var _styledComponents = require("./styled-components");
var _types = require("./types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}